/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 連携先レポートプリセット項目定義を特定するキー名
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionReportItemKey {
    Id = 'id',
    Name = 'name',
    RunningStatus = 'running_status',
    Address = 'address',
    PostalCode = 'postal_code',
    PhoneNumber = 'phone_number',
    FaxNumber = 'fax_number',
    Url = 'url',
    Administrator = 'administrator',
    TagIds = 'tag_ids',
    FacilityTypeIds = 'facility_type_ids',
    OneFacilityTypeId = 'one_facility_type_id',
    SpecialtyIds = 'specialty_ids',
    MedicalInstitutionDistance = 'medical_institution_distance',
    MedicalInstitutionMemo = 'medical_institution_memo',
    ReferralAdmissionSegmentIds = 'referral_admission_segment_ids',
    ReferralCount = 'referral_count',
    ReferralAdmissionCount = 'referral_admission_count',
    ReferralAdmissionRate = 'referral_admission_rate',
    ReferralAdmissionDiagnosisCount = 'referral_admission_diagnosis_count',
    ReferralOperationCount = 'referral_operation_count',
    ReferralOperationRate = 'referral_operation_rate',
    ReverseReferralCount = 'reverse_referral_count',
    StandardRevenue = 'standard_revenue',
    SegmentId = 'segment_id',
    Nps = 'nps',
    LastVisitDate = 'last_visit_date',
    LastDoctorVisitDate = 'last_doctor_visit_date',
    LastReferralRefuseDate = 'last_referral_refuse_date',
    Formula = 'formula',
    FormulaRate = 'formula_rate',
    CommunicationCount = 'communication_count',
    Latitude = 'latitude',
    Longitude = 'longitude',
    SecondaryEmergencyCertification = 'secondary_emergency_certification',
    TertiaryEmergencyCertification = 'tertiary_emergency_certification',
    PhaseMasterId = 'phase_master_id',
    DaysFromPhaseChangedDate = 'days_from_phase_changed_date'
}

export function MedicalInstitutionReportItemKeyFromJSON(json: any): MedicalInstitutionReportItemKey {
    return MedicalInstitutionReportItemKeyFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemKey {
    return json as MedicalInstitutionReportItemKey;
}

export function MedicalInstitutionReportItemKeyToJSON(value?: MedicalInstitutionReportItemKey | null): any {
    return value as any;
}

