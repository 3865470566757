import { CurrentUserFeaturesEnum } from '@/src/api/generated';
import { AccountState } from '@/src/hooks/useAccount';

import { isFeatureFlagEnabled } from './featureFlag';

import compact from 'lodash.compact';

import {
  AccountCircle,
  CustomSvgIconComponent,
  Description,
  Email,
  Equalizer,
  EventNote,
  Label,
  LocationOn,
  Notifications,
  Numbers,
  PeopleAlt,
  PlaylistAdd,
  PlaylistAddCheck,
  SpaceDashboard,
} from '../components/foundations/DesignToken/Icons';

type MainUrl = string;
type SubUrls = string[];

export type Menu = {
  text: string;
  urls: [MainUrl, ...SubUrls];
  value: CurrentUserFeaturesEnum;
  icon: CustomSvgIconComponent;
};

export const ALL_MENUS: Menu[] = compact([
  {
    text: 'データ分析',
    urls: ['/data_analysis'],
    value: CurrentUserFeaturesEnum.DataAnalysis,
    icon: Equalizer,
  },
  {
    text: '診療圏分析マップ',
    urls: ['/segment_map'],
    value: CurrentUserFeaturesEnum.SaleMap, // 営業分析マップと共用
    icon: LocationOn,
  },
  {
    text: '営業分析マップ',
    urls: ['/sale_map'],
    value: CurrentUserFeaturesEnum.SaleMap,
    icon: LocationOn,
  },
  {
    text: 'ダッシュボード',
    urls: ['/dashboards'],
    value: CurrentUserFeaturesEnum.Dashboard,
    icon: SpaceDashboard,
  },
  {
    text: 'レポート',
    urls: [
      '/medical_institutions_reports',
      '/own_department_reports',
      '/area_reports',
      '/communication_reports',
    ],
    value: CurrentUserFeaturesEnum.Report,
    icon: Description,
  },
  {
    text: '連携先管理',
    urls: ['/medical_institutions_search'],
    value: CurrentUserFeaturesEnum.MedicalInstitutionsSearch,
    icon: PeopleAlt,
  },
  {
    text: 'コミュニケーション',
    urls: ['/communications'],
    value: CurrentUserFeaturesEnum.Communications,
    icon: EventNote,
  },
  isFeatureFlagEnabled('FEATURE_FLAG_VOC')
    ? {
        text: 'VoC',
        urls: ['/vocs'],
        value: CurrentUserFeaturesEnum.Communications, // コミュニケーションと共用
        icon: EventNote,
      }
    : null,
  {
    text: 'タスク',
    urls: ['/tasks'],
    value: CurrentUserFeaturesEnum.Tasks,
    icon: PlaylistAddCheck,
  },
  {
    text: 'アクションリスト',
    urls: ['/action_lists'],
    value: CurrentUserFeaturesEnum.ActionLists,
    icon: PlaylistAdd,
  },
  {
    text: 'アラート',
    urls: ['/alerts', '/alert_conditions'],
    value: CurrentUserFeaturesEnum.Alerts,
    icon: Notifications,
  },
  {
    text: 'HTMLメール配信',
    urls: ['/emails'],
    value: CurrentUserFeaturesEnum.Emails,
    icon: Email,
  },
  {
    text: 'ラベル管理',
    urls: ['/labels'],
    value: CurrentUserFeaturesEnum.Labels,
    icon: Label,
  },
  {
    text: 'タグ管理',
    urls: ['/tags'],
    value: CurrentUserFeaturesEnum.Tags,
    icon: Numbers,
  },
  {
    text: 'アカウント管理',
    urls: ['/accounts'],
    value: CurrentUserFeaturesEnum.Accounts,
    icon: AccountCircle,
  },
]);

// API クライアントからは型にしていないが、 バックエンド側では 下記 features はオプション機能で扱っている
// CurrentUserFeaturesEnum.Emails
// CurrentUserFeaturesEnum.ReverseReferrals
// CurrentUserFeaturesEnum.MedicalInstitutionLabels
// CurrentUserFeaturesEnum.CommunicationLabels
// CurrentUserFeaturesEnum.Report
// CurrentUserFeaturesEnum.MedicalInstitutionReport
// CurrentUserFeaturesEnum.OwnDepartmentReport
// CurrentUserFeaturesEnum.AreaReport
type OptionKey =
  | CurrentUserFeaturesEnum.Emails
  | CurrentUserFeaturesEnum.ReverseReferrals
  | CurrentUserFeaturesEnum.MedicalInstitutionLabels
  | CurrentUserFeaturesEnum.CommunicationLabels
  | CurrentUserFeaturesEnum.Report
  | CurrentUserFeaturesEnum.MedicalInstitutionReport
  | CurrentUserFeaturesEnum.OwnDepartmentReport
  | CurrentUserFeaturesEnum.AreaReport
  | CurrentUserFeaturesEnum.CommunicationReport
  | CurrentUserFeaturesEnum.Dashboard
  | CurrentUserFeaturesEnum.DiagnosisAnalysis;
export const isOptionFeatureAvailable =
  (account: AccountState) => (optionKey: OptionKey) => {
    return account.features.includes(optionKey);
  };
