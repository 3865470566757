import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { ChartSlider } from '@/src/components/foundations/Charts/ChartSlider';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';

import { Chart } from './components/Chart';

import { State } from '../../reducer';
import { ChartData } from '../../types';

type Props = {
  dateUnit: State['dateUnit'];
  setDateUnit: (dateUnit: State['dateUnit']) => void;
  data: ChartData | undefined;
} & React.ComponentProps<typeof ChartSlider>;

const CHART_HEIGHT = 456;

export const ChartWithControlPresenter: React.FC<Props> = (props) => (
  <Stack gap={16} align="stretch" width="initial">
    <Section padding={'20px 24px'} width="initial">
      <Cluster
        align="center"
        gap={24}
        justify="flex-end"
        wrap="nowrap"
        width="initial"
      >
        <SelectDateUnit
          options={['month', 'halfYear', 'year']}
          selectedUnit={props.dateUnit}
          onClickUnit={props.setDateUnit}
        />
        <ChartSlider
          currentItemIndex={props.currentItemIndex}
          itemsCount={props.itemsCount}
          visibleItemsCount={props.visibleItemsCount}
          onClickNext={props.onClickNext}
          onClickPrev={props.onClickPrev}
        />
      </Cluster>
    </Section>
    <Section padding={'0 24px 24px 24px'} width="initial" height={CHART_HEIGHT}>
      {props.data ? (
        <Chart
          dateUnit={props.dateUnit}
          data={props.data.slice(
            props.currentItemIndex,
            props.currentItemIndex + props.visibleItemsCount,
          )}
        />
      ) : (
        <Cluster align="center" justify="center" height={'100%'}>
          <LoadingCircle />
        </Cluster>
      )}
    </Section>
  </Stack>
);
