import { CSSProperties } from 'react';

type Props = {
  inline?: boolean;
  gap?: CSSProperties['gap'];
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  flexGrow?: CSSProperties['flexGrow'];
  children: React.ReactNode;
};

export const Cluster: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        display: props.inline ? 'inline-flex' : 'flex',
        alignItems: props.align || 'center',
        justifyContent: props.justify || 'flex-start',
        gap: props.gap,
        flexDirection: 'row',
        flexWrap: props.wrap || 'wrap',
        height: props.height,
        width: props.width || '100%',
        flexGrow: props.flexGrow,
      }}
    >
      {props.children}
    </div>
  );
};
