// eslint-disable-next-line no-restricted-imports
import styles from './styles.module.scss';
import { Props } from './types';

import { SvgIcon } from '@mui/material';
import clsx from 'clsx';

export const SortNavigationDesc: React.FC<Props> = (props) => (
  <SvgIcon
    className={clsx(props.className, props.color ? styles[props.color] : null)}
    onClick={props.onClick}
    style={{
      height: props.size ? `${props.size}px` : undefined,
      width: props.size ? `${props.size}px` : undefined,
      cursor: props.cursor,
    }}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1148_3506)">
        <path d="M7 10L12 5L17 10H7Z" fill="#B5B9BA" />
        <path d="M7 14L12 19L17 14H7Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1148_3506">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
